import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { FormPages, MainPages, ProductPages, ProfilePages, ChatPage } from '../pages';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NotFound from '../pages/notFound';
import { NavigationBar, HelpersComponent } from '../components';
import { Footer } from '../components/footer';
import { SecurityComponents } from '../components';
import { Background } from '../styles';
import { ChristmasDesktopBg, ChristmasMobileBg } from '../assets/img';


const { IBModal } = HelpersComponent;
const MainRoutes = ({ theme }) => {
  const [showIbModal, setShowIbModal] = useState(false)
  // Internet banking modal
const r = {
  openModal(){
    setShowIbModal(true)
  },

  closeModal(){
    setShowIbModal(false)
  }
}

  return (
    <>
    <NavigationBar.Navigation r={r} />
    <Background bg={`url(${ChristmasDesktopBg})`} mobile={`url(${ChristmasMobileBg})`}>
    <Routes>
      <Route exact path='/' element={<MainPages.Landing theme={theme} />} />
      {/* <Route path='/two' element={<MainPages.Landing2 theme={theme} />} /> */}
      <Route
        path='/personal'
        element={<MainPages.Personal theme={theme} />}
      />
      <Route path='/business' element={<MainPages.Business theme={theme} />} />
      <Route path='/loans' element={<MainPages.Loan theme={theme} />} />
      <Route path='/about' element={<MainPages.About theme={theme} />} />
      <Route path='/about/story' element={<MainPages.Story theme={theme} />} />
      <Route
        path='/sustainability'
        element={<MainPages.Sustainability theme={theme} />}
      />
      <Route
        path='/agm'
        element={<MainPages.Agm theme={theme} />}
      />
      <Route
        path='/egm'
        element={<MainPages.Egm theme={theme} />}
      />
      <Route
        path='/investor-relations'
        element={<MainPages.Relations theme={theme} />}
      />

      <Route path='/ways-to-bank' element={<MainPages.Ways theme={theme} />} />
      <Route path='/help' element={<MainPages.Help theme={theme} />} />
      <Route path='/careers' element={<MainPages.Careers theme={theme} />} />
      <Route
        path='/whistle-blower'
        element={<MainPages.Whistle theme={theme} />}
      />
      <Route
        path='/construction-finance'
        element={<MainPages.Construction theme={theme} />}
      />
      <Route
        path='/privacy-policy'
        element={<MainPages.Policy theme={theme} />}
      />
      <Route
        path='/security-policy'
        element={<SecurityComponents.SecurityPolicy theme={theme} />}
      />
      <Route
        path='/terms-and-conditions'
        element={<MainPages.Terms theme={theme} />}
      />
      <Route path='/media' element={<MainPages.Media theme={theme} />} />
      <Route path='/blog' element={<MainPages.Blog theme={theme} />} />
      <Route path='/security-and-compliance' element={<MainPages.Security theme={theme} />} />

      {/* Products */}
      {/* Personal Products Routes */}
      <Route
        path='/personal/abbey-save'
        element={<ProductPages.AbbeySave theme={theme} />}
      />
      <Route
        path='/personal/abbey-plus'
        element={<ProductPages.AbbeyPlus theme={theme} />}
      />
      <Route
        path='/personal/abbey-zoomers'
        element={<ProductPages.AbbeyZoomers theme={theme} />}
      />
      <Route
        path='/personal/bloom-teens'
        element={<ProductPages.BloomTeens theme={theme} />}
      />
      <Route
        path='/personal/fixed-deposit'
        element={<ProductPages.FixedDeposit theme={theme} />}
      />
      <Route
        path='/personal/bloom-kidz'
        element={<ProductPages.BloomKidz theme={theme} />}
      />
      <Route
        path='/personal/current-account'
        element={<ProductPages.CurrentAccount theme={theme} />}
      />
      <Route
        path='/personal/savings-account'
        element={<ProductPages.SavingsAccount theme={theme} />}
      />
      {/* End of Personal Products Routes */}

      {/* Business Products Routes */}
      <Route
        path='/business/abbey-biz'
        element={<ProductPages.AbbeyBiz theme={theme} />}
      />
      <Route
        path='/business/biz-extra'
        element={<ProductPages.BizExtra theme={theme} />}
      />
      <Route
        path='/business/fixed-deposit'
        element={<ProductPages.FixedDeposit theme={theme} />}
      />
      {/* End of Business Products Routes */}

      {/* Mortgage and Construction Finance Form */}
      <Route
        path='/loans/form'
        element={<ProductPages.MortgageForm theme={theme} />}
      />
      <Route
        path='/construction-finance/form'
        element={<ProductPages.ConstructionForm theme={theme} />}
      />
      {/* End of Mortgage and Construction Finance Form */}
      {/* Loan products routes */}
      <Route
        path='/loans/mortgage-loan'
        element={<ProductPages.Mortgage />}
        theme={theme}
      />
      <Route
        path='/loans/equity-release'
        element={<ProductPages.Equity />}
        theme={theme}
      />
      <Route
        path='/loans/mortgage-refinancing'
        element={<ProductPages.Refinancing />}
        theme={theme}
      />
      <Route
        path='/loans/real-estate'
        element={<ProductPages.Estate />}
        theme={theme}
      />
      <Route 
        path='/loans/mortgage/calculator' 
        element={<MainPages.Calculator 
        theme={theme} />} 
      />
      {/* End of loan products routes */}

      {/* Ways to bank products */}
      <Route
        path='/ways-to-bank/abbey-cards'
        element={<ProductPages.AbbeyCards />}
        theme={theme}
      />
      <Route
        path='/ways-to-bank/quick-dial'
        element={<ProductPages.QuickDial />}
        theme={theme}
      />
      <Route
        path='/ways-to-bank/abbey-mobile'
        element={<ProductPages.AbbeyMobile />}
        theme={theme}
      />
      <Route
        path='/loans/wire'
        element={<ProductPages.Wire />}
        theme={theme}
      />

      {/* About Pages */}
      <Route
        path='/about/corporate-governance'
        element={<ProductPages.Governance />}
        theme={theme}
      />
      <Route
        path='/about/board'
        element={<ProductPages.Board />}
        theme={theme}
      />
      <Route
        path='/about/senior-management'
        element={<ProductPages.Management />}
        theme={theme}
      />
      <Route
        path='/about/leadership-and-governance'
        element={<ProductPages.Leadership />}
        theme={theme}
      />
      <Route
        path='/about/governance-structure'
        element={<ProductPages.GovernanceStructure />}
        theme={theme}
      />
      <Route
        path='/about/board-committees'
        element={<ProductPages.BoardCommitte />}
        theme={theme}
      />
      <Route
        path='/about/board-responsibilities'
        element={<ProductPages.Responsibility />}
        theme={theme}
      />
      {/* End of about pages */}

      {/* Form pages */}
      <Route path='/open-account' element={<FormPages.Main />} theme={theme} />
      <Route
        path='/personal-form'
        element={<FormPages.PersonalForm />}
        theme={theme}
      />
      <Route
        path='/business-form'
        element={<FormPages.BusinessForm />}
        theme={theme}
      />
      <Route
        path='/ways-to-bank/request-card'
        element={<FormPages.CardRequest />}
        theme={theme}
      />
      {/* End of Form Pages */}

      {/* Profile Pages */}
      <Route
        path='/profile/mobolaji-adewumi'
        element={<ProfilePages.Mobolaji />}
        theme={theme}
      />
      <Route
        path='/profile/oladipupo-ayodele'
        element={<ProfilePages.Dipo />}
        theme={theme}
      />
      <Route
        path='/profile/geoff-amaghereonu'
        element={<ProfilePages.Geoff />}
        theme={theme}
      />
      <Route
        path='/profile/lolita-ejiofor'
        element={<ProfilePages.Lolita />}
        theme={theme}
      />
      <Route
        path='/profile/obinna-ufudo'
        element={<ProfilePages.Obinna />}
        theme={theme}
      />
      <Route
        path='/profile/adenike-kuti'
        element={<ProfilePages.Adenike />}
        theme={theme}
      />
      <Route
        path='/profile/samuel-oni'
        element={<ProfilePages.Oni />}
        theme={theme}
      />
      <Route
        path='/profile/christabel-onyejekwe'
        element={<ProfilePages.Christabel />}
        theme={theme}
      />
      <Route
        path='/profile/chika-ochonogor'
        element={<ProfilePages.Chika />}
        theme={theme}
      />
      <Route
        path='/profile/olufemi-soewu'
        element={<ProfilePages.Soewu />}
        theme={theme}
      />
      <Route
        path='/profile/marius-umego'
        element={<ProfilePages.Umego />}
        theme={theme}
      />
      <Route
        path='/profile/jewel-okwechime'
        element={<ProfilePages.Jewel />}
        theme={theme}
      />
      <Route
        path='/profile/christabel-onyejekwe'
        element={<ProfilePages.Christabel />}
        theme={theme}
      />
      <Route
        path='/profile/chika-ochonogor'
        element={<ProfilePages.Chika />}
        theme={theme}
      />
      <Route
        path='/profile/john-obasa'
        element={<ProfilePages.Obasa />}
        theme={theme}
      />
      <Route
        path='/profile/abiodun-lasisi'
        element={<ProfilePages.Lasisi />}
        theme={theme}
      />
      <Route
        path='/profile/olushola-seidu'
        element={<ProfilePages.Olusola />}
        theme={theme}
      />
      <Route
        path='/profile/toyin-abel'
        element={<ProfilePages.Toyin />}
        theme={theme}
      />
      <Route
        path='/profile/tosin-beredugo'
        element={<ProfilePages.Tosin />}
        theme={theme}
      />
      <Route
        path='/profile/felix-omodayo-owotuga'
        element={<ProfilePages.Felix />}
        theme={theme}
      />
      <Route
        path='/profile/bukola-ewedairo'
        element={<ProfilePages.Bukola />}
        theme={theme}
      />
      <Route
        path='/profile/funmi-femi-omage'
        element={<ProfilePages.Funmi />}
        theme={theme}
      />
      <Route
        path='/profile/oluwatomi-olurinola'
        element={<ProfilePages.Tomi />}
        theme={theme}
      />
      <Route
        path='/profile/idongesit-abia'
        element={<ProfilePages.Idongesit />}
        theme={theme}
      />
      <Route
        path='/profile/ebenezer-afolabi'
        element={<ProfilePages.Ebenezer />}
        theme={theme}
      />
      <Route path='*' element={<NotFound />} />
      {/* End of Profile Pages */}

      {/* Chat */}
      <Route path='/chat' element={<ChatPage />}  />
      {/* End of Chat */}
    </Routes>
      <Footer />
      </Background>
      { showIbModal && <IBModal r={r} setModal={setShowIbModal} /> }
    </>
  );
};

AOS.init({
  once: false,
  offset: 100,
  easing: 'ease-in-out',
  delay: 50,
  duration: 1500,
  disable: () => {
    const maxWidth = 900
    return window.innerWidth < maxWidth
  }
});
export default MainRoutes;
